import Rollbar, { Configuration, LogArgument, Dictionary } from 'rollbar';
import { env } from '@/env';
import hash from 'object-hash';

type LoggingTypes = 'log' | 'error' | 'info' | 'debug' | 'critical' | 'warn' | 'warning';

const clientToken = env.NEXT_PUBLIC_ROLLBAR_CLIENT_TOKEN;
const serverToken = env.NEXT_PUBLIC_ROLLBAR_SERVER_TOKEN;

export const hasToken = clientToken || serverToken;

const getRollbarEnvironment = (isClient: boolean) => {
  const project = isClient ? 'client' : 'server';

  return `${project}.${env.NEXT_PUBLIC_ROLLBAR_ENV || process.env.NODE_ENV}`;
};

export const getRollbarConfig = (isClient: boolean) => {
  const token = isClient ? clientToken : serverToken;
  return {
    enabled: !!token,
    environment: getRollbarEnvironment(isClient),
    accessToken: token,
    retryInterval: 1000,
    captureUncaught: true,
    captureUnhandledRejections: true,
    addErrorContext: true,
    // ignoredMessages: [/Minified React error/], // Ignoring minified error
    payload: {
      client: {
        javascript: {
          // eslint-disable-next-line camelcase
          source_map_enabled: true,
          // eslint-disable-next-line camelcase
          code_version: env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
          // eslint-disable-next-line camelcase
          guess_uncaught_frames: true,
        },
      },
    },
    checkIgnore: (...args) => {
      return !shouldLog(...args);
    },
  } as Configuration;
};

export const createRollbarInstance = (isClient: boolean) => new Rollbar(getRollbarConfig(isClient));

const rollbarInstance = createRollbarInstance(typeof window !== 'undefined');

export const handleRollbarLog =
  (type: LoggingTypes, instance?: Rollbar) =>
  (...args: LogArgument[]) => {
    const isClient = typeof window !== 'undefined';
    const token = isClient ? clientToken : serverToken;

    if (instance && process.env.NODE_ENV === 'development') {
      if (token) {
        console.log(`Sending to Rollbar from ${isClient ? 'client' : 'server'} side`, args);
      } else {
        console.log(
          `Error should be sent to Rollbar from ${isClient ? 'client' : 'server'} side`,
          args,
        );
      }
    }

    if (instance && token) {
      instance[type](...args);
    }
  };

// This rollbar variable should be used on server side ONLY. For client side use rollbar from @rollbar/react provider
const rollbar = {
  log: handleRollbarLog('log', rollbarInstance),
  error: handleRollbarLog('error', rollbarInstance),
  info: handleRollbarLog('info', rollbarInstance),
  debug: handleRollbarLog('debug', rollbarInstance),
  critical: handleRollbarLog('critical', rollbarInstance),
  warn: handleRollbarLog('warn', rollbarInstance),
  warning: handleRollbarLog('warning', rollbarInstance),
};

type LoggingItem = {
  key: string;
  count: number;
  resetAt: Date;
};

// Saving errors that are happening
const errorCache: { [key: string]: LoggingItem } = {};

// Function to determine if the error should be logged
const shouldLog = (_isUncaught: boolean, args: LogArgument[], _item: Dictionary): boolean => {
  try {
    // Generating a unique key (hash) for the error
    const errorObj = {} as { [key: number]: unknown };
    for (let i = 0; i < args.length; i++) {
      errorObj[i] = args[i];
    }
    const key = hash(errorObj, { ignoreUnknown: true });

    const timeWindow =
      (errorObj[1] as { timeWindowMin: number })?.timeWindowMin || // Defined on function calling
      env.NEXT_PUBLIC_TIME_WINDOW_MIN || // Defined on env
      60; // Default value
    const now = new Date();
    const timeWindowMs = timeWindow * 60000; // Time window in milliseconds (here, 1 minute = 60,000 ms)
    const maxCount = 2; // Maximum number of times the error can be logged within the time window

    if (!errorCache[key]) {
      // Error is not in the cache, add it to the cache
      errorCache[key] = {
        key,
        count: 1,
        resetAt: new Date(now.getTime() + timeWindowMs),
      };
      return true; // Log the error
    }

    // Error already logged
    const loggingItem = errorCache[key];

    // If the reset time has passed, reset the counter and the reset time
    if (loggingItem.resetAt <= now) {
      loggingItem.count = 1;
      loggingItem.resetAt = new Date(now.getTime() + timeWindowMs);
      return true; // Log the error
    }

    // Increment the error counter
    loggingItem.count += 1;

    // Check if the counter exceeds the limit
    if (loggingItem.count > maxCount) {
      console.log('An error happened too much, skipping it for now', { args });
      return false; // Do not log the error
    }

    return true; // Log the error
  } catch (error) {
    console.error('Failed to parse rollbar error', error);
    return true; // Failed to check, so log the error
  }
};

export default rollbar;
