import React, { PropsWithChildren, createContext, useContext, useEffect, useRef } from 'react';
import useApi from '@/hooks/useApi/useApi';
import { useFirebaseMessaging } from '@/hooks/useFirebaseMessaging';
import { useStore } from '@/stores/store';
import { User } from '@/typings/user';
import useRollbar from '@/hooks/useRollbar';
import { initMessaging } from '@/utils/firebase';

type NotificationBannerType = {
  requestPermission: () => Promise<void>;
  token: string | null;
  permissionStatus: NotificationPermission;
};

const NotificationBannerContext = createContext({} as NotificationBannerType);

const NotificationBannerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const verifiedToken = useRef(false);

  const updateData = useStore((store) => store.updateData);
  const user = useStore((store) => store.user);

  const [requestPermission, { permissionStatus, token }] = useFirebaseMessaging();
  const { post } = useApi('notificationToken');
  const rollbar = useRollbar();

  useEffect(() => {
    if (!user) return; // Should only post to the backend when the user is available
    if (verifiedToken.current) return; // Token already posted

    const userMissingTokens = (token?: string | null) => {
      const tokens = (user.notificationTokensWeb || []).filter((tk) => !tk.deactivatedAt);
      if (!tokens) return true;
      if (token && !tokens.some((tk) => tk.token === token)) return true;
      return false;
    };
    if (!userMissingTokens(token)) return; // User already have tokens

    const handleTokenPost = (token: string) => {
      rollbar.debug('Adding new token to a user', { user, token, permissionStatus });
      post(
        { token, userAgent: navigator.userAgent },
        {
          error: {
            message: 'Error on sending to API the notification token',
            showNotification: false,
          },
          onSuccess: (updatedUser: User) => {
            updateData<User>('user', (user) => ({ ...user, ...updatedUser }));
            verifiedToken.current = true;
          },
        },
      );
    };

    if (token) {
      console.log(`[notification] Token generated`);
      verifiedToken.current = true;
      handleTokenPost(token);
      return;
    }

    // No token
    if (permissionStatus === 'granted') {
      console.log(`[notification] Forcing token generation`);
      // This code sets the notification token in the backend if the user has already allowed notifications before and does not have any notificationToken.
      // A situation that it might happen:
      // User logs in with an account and then accept notification permission.
      // After a while he logs out and then reconnect with a different account and the permission was already granted before
      verifiedToken.current = true;

      initMessaging(true)
        .then((newToken) => newToken && handleTokenPost(newToken))
        .catch((err) =>
          rollbar.error('Failed to start Firebase cloud messaging - notificationBanner', err),
        );
    }
    console.log(`[notification] Permission not granted`);
  }, [user, permissionStatus, token]);

  return (
    <NotificationBannerContext.Provider value={{ permissionStatus, token, requestPermission }}>
      {children}
    </NotificationBannerContext.Provider>
  );
};

export const useNotificationBanner = () => useContext(NotificationBannerContext);

export default NotificationBannerProvider;
