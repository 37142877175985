import { Organization } from '@/typings/backend';
import { StoreInterface } from '@/stores/store';
import { StateCreator } from 'zustand';
import { ACTIVE_ORGANIZATIONS, INACTIVE_ORGANIZATIONS, ORGANIZATION } from '@/utils/storageKeys';
import { setCookie } from 'cookies-next';

export type AppDataSlice = {
  // organization: Organization | null;
  selectedOrganization: Organization | null;
  organizationData: Organization[] | null;
  emitNewMessageSound: boolean;
  setEmitNewMessageSound: (value: boolean) => void;
  // setOrganization: (org: Organization) => void;
  setSelectedOrganization: (value: string | Organization) => void;
  setOrganizationData: (value: Organization[]) => void;
};

export const createAppDataSlice: StateCreator<
  StoreInterface,
  [],
  [],
  AppDataSlice /* Current slice type */
> = (set, get) => ({
  // organization: null,
  selectedOrganization: null,
  organizationData: [],
  emitNewMessageSound: false,
  setEmitNewMessageSound: (value) => {
    set({ emitNewMessageSound: value });
  },
  // setOrganization: (organization) => {
  //   set({ organization });
  // },
  setSelectedOrganization: (value) => {
    let org: Organization | null = value as Organization;

    if (typeof value === 'string') {
      const orgData = get().organizationData;
      org = orgData?.find((org) => org.id === value) || null;
    }

    if (!org) return;

    const partialOrg = {
      id: org.id,
      name: org.name,
      active: org.active,
      slug: org.slug,
      createdAt: org.createdAt,
    };

    const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
    setCookie(ORGANIZATION, JSON.stringify(partialOrg), { maxAge });

    set({ selectedOrganization: org });
  },
  setOrganizationData: (data) => {
    const { active, inactive } = data.reduce(
      (acc, org) => {
        const data = { id: org.id, name: org.name };

        if (org.active) {
          acc.active.push(data);
        } else {
          acc.inactive.push(data);
        }

        return acc;
      },
      { active: [], inactive: [] } as Record<string, { id: string; name: string }[]>,
    );

    // One year
    const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
    setCookie(ACTIVE_ORGANIZATIONS, JSON.stringify(active), { maxAge });
    setCookie(INACTIVE_ORGANIZATIONS, JSON.stringify(inactive), { maxAge });

    set({ organizationData: data });

    const org = get().selectedOrganization;

    if (org?.id) {
      const orgData = data.find((o) => o.id === org.id);
      if (orgData) {
        const partialOrg = {
          id: org.id,
          name: org.name,
          active: org.active,
          slug: org.slug,
          createdAt: org.createdAt,
        };

        const maxAge = 1000 * 60 * 60 * 24 * 365; // 1 year for now
        setCookie(ORGANIZATION, JSON.stringify(partialOrg), { maxAge });
        set({ selectedOrganization: orgData });
      }
    }
  },
});
