export const USER_TOKEN = 'token';
export const FLOATING_THREADS = 'floatingThreads';
export const NAVBAR_COLLAPSED = 'navbar-collapsed';
export const NOTIFICATION_BANNER = 'notification-banner';
export const PERMISSION_STATUS = 'permission-status';
export const PUBLIC_THREAD_ID = 'public-thread-id';
export const PUBLIC_TEST_THREAD_ID = 'public-test-thread-id';
export const PUBLIC_ASSISTANT_ID = 'public-assistant-id';
export const THREADS_READ_STATUS = 'threads-read-status';
export const CHAT_FILTERS = 'chat-filters-selected';
export const DASHBOARD_DATE_RANGE = 'dashboard-date-range';
export const DASHBOARD_HAS_DATA = 'dashboard-has-data';
export const DASHBOARD_INSIGHTS = 'dashboard-insights';
export const CHAT_SIDEBAR_OPENED = 'sidebar-collapsed';
export const ORGANIZATION = 'organization-id';
export const ACTIVE_ORGANIZATIONS = 'active-organizations';
export const INACTIVE_ORGANIZATIONS = 'inactive-organizations';
